import React, { Component, Suspense } from 'react';

// Importing Section
const Navbar = React.lazy(() => import('../../component/Navbar/NavBar'));
const Carousel = React.lazy(() => import('../../component/Carousel'));
// const Sejarah = React.lazy(() => import('../../component/Sejarah'));
const VisiMisi = React.lazy(() => import('../../component/VisiMisi'));
const Penduduk = React.lazy(() => import('../../component/Penduduk'));
const Dusun = React.lazy(() => import('../../component/Dusun'));
const Footer = React.lazy(() => import('../../component/Footer/Footer'));
const Artikel = React.lazy(() => import('../../component/Artikel'));
const DesaKami = React.lazy(() => import('../../component/DesaKami'));
const PetaDesa = React.lazy(() => import('../../component/PetaDesa'));

const AparaturDesa = React.lazy(() => import('../../component/AparaturDesa'));
const Pariwisata = React.lazy(() => import('../../component/Pariwisata'));
const Galeri = React.lazy(() => import('../../component/Galeri'));
const Komunitas = React.lazy(() => import('../../component/Komunitas'));
const Fasilitas = React.lazy(() => import('../../component/Fasilitas'));
const Layanan = React.lazy(() => import('../../component/Layanan'));
const Struktur = React.lazy(() => import('../../component/Struktur'));
const Komoditas = React.lazy(() => import('../../component/Komoditas'));

class Layout1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: 'beranda', navheading: 'Beranda' },
        // { id: 2, idnm: 'sejarah', navheading: 'Sejarah' },
        { id: 3, idnm: 'visi-misi', navheading: 'Visi Misi' },
        { id: 4, idnm: 'penduduk', navheading: 'Penduduk' },
        { id: 5, idnm: 'dusun', navheading: 'Dusun' },
        { id: 6, idnm: 'article', navheading: 'Artikel' },
        { id: 7, idnm: 'DesaKami', navheading: 'Desa Kami' },
        { id: 8, idnm: 'PetaDesa', navheading: 'Peta' },
        { id: 10, idnm: 'galery', navheading: 'Galeri' },
        { id: 11, idnm: 'struktur', navheading: 'Struktur' },
        { id: 12, idnm: 'aparatur-desa', navheading: 'Aparatur Desa' },
        // { id: 12, idnm: 'pariwisata', navheading: 'Pariwisata' },
        { id: 13, idnm: 'fasilitas', navheading: 'Fasilitas' },
        // { id: 14, idnm: 'komunitas', navheading: 'Komunitas' },
        { id: 15, idnm: 'komoditas', navheading: 'Komoditas' },
        { id: 16, idnm: 'layanan', navheading: 'Layanan Desa' },
      ],
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: '',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: 'nav-sticky', imglight: false });
    } else {
      this.setState({ navClass: '', imglight: false });
    }
  };

  //set preloader div
  PreLoader = () => {
    return (
      <div id='preloader'>
        <div id='status'>
          <div className='spinner'>
            <div className='bounce1'></div>
            <div className='bounce2'></div>
            <div className='bounce3'></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />

          {/* Carousel */}
          <Carousel />

          {/* Sejarah */}
          {/* <Sejarah /> */}

          {/* Visi Misi */}
          <VisiMisi />

          {/* Penduduk */}
          <Penduduk />

          {/* Dusun */}
          <Dusun />

          {/* Artikel */}
          <Artikel />

          {/* Desa Kami */}
          <DesaKami />

          {/* Peta */}
          <PetaDesa />

          {/* Galeri */}
          <Galeri />

          {/* Struktur */}
          <Struktur />

          {/* Aparatur Desa */}
          <AparaturDesa />

          {/* Parawisata */}
          {/* <Pariwisata /> */}

          {/* Fasilitas */}
          <Fasilitas />

          {/* Komunitas */}
          {/* <Komunitas /> */}

          {/* Komoditas */}
          <Komoditas />

          {/* Layana Desa */}
          <Layanan />

          {/* Importing Footer */}
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default Layout1;
