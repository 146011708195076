import React, { Component } from 'react';
import routes from '../src/routes';
import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router,
} from 'react-router-dom';

//import style
import './assets/css/pe-icon-7.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './assets/scss/themes.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    AOS.init();
  }

  render() {
    const domain = window.location.host.split('.')[0];
    // console.log('domain: ', domain);
    if (window.location.host.split('.')[0] === 'localhost:3000') {
      // console.log('projects component');
    }

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {routes.map((route, idx) => (
              <Route path={route.path} component={route.component} key={idx} />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
