import React from 'react'
import "./DetailArtikel.scss";
import Rumah from '../../assets/images/Rumah.png';
import { Link, useParams } from 'react-router-dom';
import FeatherIcon from "feather-icons-react";
import computer from '../../assets/images/computer.png';

const DetailArtikel = () => {
  const {id} = useParams()
  return (
    <section id="DetailArtikel">
      <div className="navbar">
        <Link to={"/"} className="link-back">
          <FeatherIcon icon="arrow-left"/>
          Kembali
        </Link>
      </div>
      <div className="container">
        
        <div className="news-content">
          {id === "polres-oku-juga-laksanakan-jumat-curhat-kali-ini-di-kantor-desa-banuayu" ? 
          <>
          <h2>Polres Oku Juga Laksanakan Jum'at Curhat, kali ini Di Kantor Desa Banuayu Lubuk Batang</h2>
            <p className='news-date'>Jumat, 17/03/2023, 21.00 WIB</p>
            <img src={Rumah} alt="News Image" />
            <p>Baturaja-Kabag SDM Polres Oku Kompol Wahyu Prasetyo, S.H., M.Si., mewakili Kapolres Oku melaksanakan kegiatan Jum'at Curhat yang kali ini dilaksanakan di Kantor Desa Banuayu Kec. Lubuk Batang Kab. Oku. Jum'at (17/03/2023).

            Kegiatan Jum'at Curhat tersebut dihadiri Kabag SDM Polres Oku Kompol Wahyu Prasetyo, S.H., M.Si.

            Kapolsek Lubuk Batang Akp Marjuni, S.E., M.Si., bersama Bhabinkamtibmas dan Babinsa Lubuk Batang, Perwira Bidkum Polres Oku Iptu Gaguk Suhaimi, Sat Binmas Polres Oku, Tokoh Agama, Tokoh Adat dan Toko Masyarakat serta warga masyarakat Desa Banuayu.

            Dalam kegiatan Jum'at Curhat ini, kami Polres Oku menerima saran dan keluhan warga masyarakat Desa Banuayu, silahkan kepada warga untuk bertanya. Ujar Kabag SDM Polres Oku Kompol Wahyu Prasetyo, S.H., M.SI.

            Selanjutnya pertanyaan dari salah satu warga terkait kenapa orgen tunggal tidak boleh diadakan akan tetapi tempat diskotik (hiburan malam) tidak ditutup.

            Pertanyaan selanjutnya adakah jalan termudah untuk kami yang belum mimiliki SIM, bahwa diantara kami masih ada yang belum punya SIM, dan Langkah apa yang akan diambil supaya narkoba ini hangus sehangus nya dari bumi ini

            Dan terakhir masalah pupuk sudah langka untuk sekarang, apa sanksi hukum orang yang melakukan penimbunan terhadap pupuk

            Dari pertanyaan yang diajukan oleh warga masyarakat tersebut, Kabag SDM Polres Oku Kompol Wahyu Prasetyo, S.H., M.Si., langsung menjawab terkait tempat diskotik (hiburan malam) tidak ditutup nanti kita koordinasi sama pihak pemda agar mengeluarkan peraturan tentang adanya</p>
     
          </> 
          : 
          <>
          <h2>Ular sanca kembang menghebohkan warga komplek perkebunan PT Minanga Ogan Kecamatan Lubuk Batang, kemarin (28/2) siang.</h2>
            <p className='news-date'>Rabu, 01/03/2023, 09:23 WIB</p>
            <img src={Rumah} alt="News Image" />
            <p> Ular sanca kembang menghebohkan warga komplek perkebunan PT Minanga Ogan Kecamatan Lubuk Batang, kemarin (28/2) siang. 
              Hal ini dikarenakan ular melata itu memasuki kawasan pemukiman penduduk. Tidak hanya itu, hewan tersebut juga merugikan warga. Karena sudah dua kali memakan hewan ternak warga. Keberadaan hewan ini, sambung dia, sudah terlihat sejak seminggu terakhir. Hal ini membuat warga resah. 
              Karena dikhawatirkan menyerang warga di kawasan tersebut. 
              “Siang kemarin ular sepanjang 3 meter itu melintas di depan Balai Pengobatan (BP) Minanga Ogan, “ ucapnya. ‘
              Saat sedang melintas itulah, dua warga setempat yakni Dadang dan Yogi berhasil menangkap hewan liar ini.
              Dijelaskannya, hewan tersebut berasal dari sekitaran kantor BP. Karena, di kawasan itu masih cukup rimbun.
              <br/>
              <span>Sumber Berita: <a style={{color:"blue"}} href='https://okes.disway.id/read/639893/dua-kali-makan-ayam-ular-sanca-ditangkap-warga' target="_blank">https://okes.disway.id/read/639893/dua-kali-makan-ayam-ular-sanca-ditangkap-warga</a></span>
              </p>
          </> 
          }
         </div>
        {/* <div className="news-list">
          <p className="orange-elipse">Other News</p>
          <ul>
            <li>
              <a href="news1.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Rumah} alt="News Image" />
                  </div>
                  
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news2.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Rumah} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news3.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Rumah} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news4.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Rumah} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news5.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Rumah} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
          
          <br />

          <p className="orange-elipse">Other News</p>
          <ul>
            <li>
              <a href="news1.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Rumah} alt="News Image" />
                  </div>
                  
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news2.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Rumah} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news3.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Rumah} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news4.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Rumah} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news5.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Rumah} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>


        </div> */}
      </div>
        
    </section>
  )
}

export default DetailArtikel